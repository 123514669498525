






















@import "src/assets/style/global"
.name
  /*margin-top: 30px*/
  font-size: 2.5rem
  color: white
