@mixin flex-center
  display: flex
  justify-content: center
  align-items: center

@mixin default-a
  color: unset
  text-decoration: none
  &:visited
    color: unset
